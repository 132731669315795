<template>
  <div>
    <ValidationObserver ref="form">
      <v-form @submit.prevent="updatePassword" v-if="!passwordChanged">
        <p>Por favor ingrese su nueva contraseña</p>
        <ValidationProvider
            name="Contraseña"
            vid="password"
            v-slot="{ errors }"
            rules="required|min:8|max:25"
        >
          <v-text-field
              v-model="model.password"
              outlined
              dense
              :error-messages="errors"
              label="Contraseña *"
              type="password"
              required
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
            name="Confirmar contraseña"
            vid="confirm"
            v-slot="{ errors }"
            rules="required|confirmed:password"
        >
          <v-text-field
              v-model="model.confirm"
              outlined
              dense
              :error-messages="errors"
              label="Confirmar contraseña *"
              type="password"
              required
          ></v-text-field>
        </ValidationProvider>
        <v-btn
            block
            @click="updatePassword"
            class="elevation-0 text-none w-100"
            color="primary"
            elevation="2"
            dark
        >
          Cambiar
        </v-btn>
      </v-form>
    </ValidationObserver>
    <div v-if="passwordChanged">
      <v-alert color="green" text type="success">
        !Tu contraseña ha sido restablecida Exitosamente!
      </v-alert>
      <v-btn
          block
          to="/"
          class="elevation-0 text--primary text-none w-100"
          color="secondary"
          elevation="2"
          dark
      >
        Iniciar sesión
      </v-btn>
    </div>
  </div>
</template>

<script>
import {CHANGE_PASSWORD_URL} from "@/constants/ServicesConstants";
import loaderMixin from '@/mixins/loaderMixin';
import notificationMixin from '@/mixins/notificationMixin';

export default {
  name: "ChangePassword",
  data: () => ({
    changePasswordUrl: CHANGE_PASSWORD_URL,
    model: {
      password: "",
      confirm: "",
    },
    passwordChanged: false
  }),
  mixins: [loaderMixin, notificationMixin],
  methods: {
    async updatePassword() {
      const result = await this.$refs.form.validate();
      if (result) {
        this.showLoader();
        this.$axios
            .put(this.changePasswordUrl, {
              token: this.$route.query.token,
              user: this.$route.query.username,
              password: this.model.password,
              confirm: this.model.confirm,
            })
            .then(() => {
              this.passwordChanged = true;
              this.hideLoader();
            })
            .catch((error) => {
              this.hideLoader();
              if (error && error.response && error.response.status == 422) {
                this.invalidFields();
                this.$refs.form.setErrors(error.response.data);
              } else {
                this.showError();
              }
            });
      }
    },
  },
};
</script>
